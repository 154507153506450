<template>
<div class="">
  <div class="row notFound mt-md-2 mt-4">
    <div class="description col-md-6 col-12 p-5">
      <p class="title fw-700">{{ $tc('error') }} 404</p>
      <p class="subTitle fw-600">
        {{ errorPageTitle }} {{ $tc('page_not_found') }}
      </p>
      <p class="description">
        {{ $tc('not_found_message') }}!
      </p>
    </div>
    <div class="image col-md-6 col-12 mt-md-0 mt-4">
      <img src="../../assets/images/error-404.png" />
    </div>
  </div>
</div>
</template>

<script>
// import libs
import { createNamespacedHelpers } from 'vuex';
const { mapActions: mapActionsCommon } = createNamespacedHelpers('common');

export default {
  mounted() {
    this.updateBreadcrumbs([{ name: '404', link: ""}]);
    this.errorPageTitle = this.$route.params.notFound;
  },
  data() {
    return {
      errorPageTitle: null,
    }
  },
  methods: {
    ...mapActionsCommon(['updateBreadcrumbs'])
  },
}
</script>

<style scoped>
.notFound {
  width: 100%;
  height: 100%;
  margin: 0;
}

.description .title {
  font-size: 48px;
  line-height: 36px;
  color: #4b1f78;
  margin: 0 0 16px 0;
}

.subTitle {
  font-size: 24px;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.description {
  font-size: 14px;
  color: rgb(0, 0, 0);
}

.image img {
  width: 100%;
}
</style>
