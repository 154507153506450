<template>
  <aside
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3"
    id="sidenav-main"
  >
    <!-- sidenav-header start -->
    <div class="sidenav-header">
      <i
        class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <router-link class="navbar-brand m-0" to="/">
        <img :src="api_url + '/get-image/logo'"
             class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <img
            :src="api_url + '/get-image/squared_logo'"
            class="navbar-brand-img h-100"
          alt="minimum_logo"
        />
      </router-link>
    </div>
    <!-- sidenav-header end -->
    <hr class="horizontal dark mt-0" />
    <!-- collapse navbar-collapse start -->
    <div
      class="collapse navbar-collapse w-auto h-auto"
      id="sidenav-collapse-main"
    >
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/">
            <div
              class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"
            >
              <svg
                width="12px"
                height="12px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M511.8 287.6L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L416 100.7V64C416 46.33 430.3 32 448 32H480C497.7 32 512 46.33 512 64V185L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6L511.8 287.6z"
                />
              </svg>
            </div>
            <span class="nav-link-text ms-1">{{ $tc('dashboard') }}</span>
          </router-link>
        </li>
        <li class="nav-item mt-3">
          <h6
            class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
          >
            {{ $tc('pages') }}
          </h6>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/my-course">
            <div
              class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"
            >
              <svg
                width="12px"
                height="12px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M160 80C160 53.49 181.5 32 208 32H240C266.5 32 288 53.49 288 80V432C288 458.5 266.5 480 240 480H208C181.5 480 160 458.5 160 432V80zM0 272C0 245.5 21.49 224 48 224H80C106.5 224 128 245.5 128 272V432C128 458.5 106.5 480 80 480H48C21.49 480 0 458.5 0 432V272zM400 96C426.5 96 448 117.5 448 144V432C448 458.5 426.5 480 400 480H368C341.5 480 320 458.5 320 432V144C320 117.5 341.5 96 368 96H400z"
                />
              </svg>
            </div>
            <span class="nav-link-text ms-1">{{ $tc('all_courses') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <hr class="horizontal dark" />
          <h6
            class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"
          >
            {{ $tc('management') }}
          </h6>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/individual-report">
            <div
              class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"
            >
              <svg
                width="12px"
                height="12px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M160 80C160 53.49 181.5 32 208 32H240C266.5 32 288 53.49 288 80V432C288 458.5 266.5 480 240 480H208C181.5 480 160 458.5 160 432V80zM0 272C0 245.5 21.49 224 48 224H80C106.5 224 128 245.5 128 272V432C128 458.5 106.5 480 80 480H48C21.49 480 0 458.5 0 432V272zM400 96C426.5 96 448 117.5 448 144V432C448 458.5 426.5 480 400 480H368C341.5 480 320 458.5 320 432V144C320 117.5 341.5 96 368 96H400z"
                />
              </svg>
            </div>
            <span class="nav-link-text ms-1">{{ $tc('individual_report') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/notification">
            <div
              class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"
            >
              <svg
                width="12px"
                height="12px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M256 32V51.2C329 66.03 384 130.6 384 208V226.8C384 273.9 401.3 319.2 432.5 354.4L439.9 362.7C448.3 372.2 450.4 385.6 445.2 397.1C440 408.6 428.6 416 416 416H32C19.4 416 7.971 408.6 2.809 397.1C-2.353 385.6-.2883 372.2 8.084 362.7L15.5 354.4C46.74 319.2 64 273.9 64 226.8V208C64 130.6 118.1 66.03 192 51.2V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32H256zM224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512z"
                />s
              </svg>
            </div>
            <span class="nav-link-text ms-1">{{ $tc('notifications') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- collapse navbar-collapse end -->
    <!-- sidenav-footer start -->
    <div class="sidenav-footer mx-3 mt-3 pt-3">
      <div class="sidebar-account-info shadow-none" id="sidenavCard">
        <div class="card-body text-start p-3 w-100">
          <div
            class="avatar"
            :style="`background: url(${accountInfo.profilePicture}) center center / cover no-repeat;`"
          ></div>
          <div class="docs-info account-info">
            <h6 class="name">
              {{ accountInfo.name }}
            </h6>
            <p class="type">{{ $tc('student') }}</p>
            <p class="work">{{ $tc('email') }}: <span>{{ accountInfo.email }}</span></p>
            <div class="btn-profile">
              <router-link class="btn-bg-color w-100" to="profile">{{ $tc('view_profile') }}</router-link>
            </div>
            <div class="btn-sign-out">
              <a
                class="btn-no-bg w-100"
                href="javascript:;"
                @click="requestLogout"
              >
                {{ $tc('sign_out') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sidenav-footer end -->
  </aside>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Pusher from 'pusher-js' // import Pusher
import {VUE_APP_API_HOST} from "@/services/http";


const { mapActions: mapActionsAuth, mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapActions: mapActionsUser, mapState: mapStateUser } = createNamespacedHelpers('user')
const { mapActions: mapActionsNoti } = createNamespacedHelpers('notifications')

export default {
  name: "SideBar",
  components: {},
  data() {
    return {
      notifications: [],
      api_url: VUE_APP_API_HOST
    }
  },
  computed: {
    ...mapStateUser(['accountInfo']),
    ...mapStateAuth(['isAuthenticated']),
  },
  watch: {
    isAuthenticated(val) {
      if(val) {
        this.requestGetInfo()
        this.subscribe()
      }
    }
  },
  async mounted() {
    if (this.isAuthenticated) {
      await this.requestGetInfo();
      this.subscribe()
    }
  },
  methods: {
    ...mapActionsAuth(['requestLogout']),
    ...mapActionsUser(['requestGetInfo']),
    ...mapActionsNoti(['updateNotifications']),
    ...mapActionsNoti(['appendToList']),
    subscribe () {
      const pusherAppKey = process.env.VUE_APP_PUSHER_APP_KEY
      let pusher = new Pusher(pusherAppKey, { cluster: 'eu' })
      const myChanel = `admin_notifications`
      pusher.subscribe(myChanel)
      pusher.bind('message-from-admin', data => {
        this.updateNotifications(data);
        this.appendToList(data)
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-collapse {
  .router-link-active {
    background: #fff;
    box-shadow: 0px 3.5px 5.5px rgb(0 0 0 / 2%);
    .icon {
      background: #4b1f78!important;
      svg {
       fill: #fff;
      }
    }
  }
}
</style>
