<template>
  <div class="container-fluid">
    <div class="mb-4">
      <h2 class="mb-0 page_title">{{ course.name }}</h2>
    </div>
    <div class="course-detail-overview">
      <!-- video, lessons start -->
      <div class="row">
        <!-- video start -->
        <div class="col-md-8 col-xl-9 course-content">
          <div class="card p-3 videoBlock">
            <div class="video">
              <vue-plyr
                v-if="lesson.content"
                @timeupdate="videoTimeUpdated($event)"
                @ended="endedAction"
                :emit="['timeupdate', 'ended']"
                ref="plyr"
              >
                <video
                  muted autoplay playsinline
                  :data-poster="lesson.image"
                  ref="video"
                  :src="lesson.content"
                ></video>
              </vue-plyr>
            </div>
          </div>
        </div>
        <!-- video end -->
        <!-- lessons end -->
        <div class="col-md-4 col-xl-3 course-sidebar widget__sidebar">
          <div class="card p-3 lesson mb-4 lessonsBlock">
            <div class="mh-600 lesson__inner">
              <h2 class="widget__title">{{ $tc('lessons') }}</h2>
              <div
                v-for="(item, index) in course.lessons"
                :key="item.id"
                :class="['d-flex lesson-item']" >
                <div
                  class="thumb"
                  :style="`background: url(${item.image}) center center / cover no-repeat; cursor: pointer;`"
                  @click="getDetailLesson(item.id, index)">
                </div>

                <div class="content">
                  <a
                    href="javascript:;"
                    :class="['title text__truncate -n1', { 'lesson-active': item.id === lesson.id}]"
                    @click="getDetailLesson(item.id, index)">
                    {{ item.name }}
                  </a>
                  <div class="d-flex align-items-baseline justify-content-between">
                    <div>
                      <span class="mins font-italic text-lighter">{{ item.duration }}</span>
                    </div>
                    <div v-if="item.student_learning != null"
                      :class="[item.student_learning.status === 'completed' ? 'text-bold' : '']">
                      {{ item.student_learning.status }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- lessons end -->
      </div>
      <!-- video, lessons end -->
      <!-- information, quiz results start -->
      <div class="row mt-3">
        <!-- information start -->
        <div class="col-md-8 col-xl-9 course-content">
          <div class="d-flex align-items-center head__title">
            <div class="info">
              <h2 class="title">{{ lesson.name }}</h2>
            </div>
            <div class="action_btn">
              <a class="btn btn-default-2 me-2" :href="lesson.download" >
                <i class="fa fa-download"></i>Download</a>
              <router-link
                type="button"
                class="btn btn-default-2 me-2"
                :to="`/course-discuss/${courseSlug}?course_id=${course.id}`"
              >
                <i class="fa fa-pen"></i>{{ $tc('discuss') }}
              </router-link>
              <button
                class="btn btn-default-2"
                :disabled="completeLesson"
                @click="markComplete"
              >
                <i class="fa fa-check-double"></i>{{ $tc('mark_as_completed') }}
              </button>
            </div>
          </div>
          <div class="course-tabs">
            <ul class="nav nav-tabs" id="coursetabs" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Overview"
                  type="button"
                  role="tab"
                  aria-controls="Overview"
                  aria-selected="true"
                >{{ $tc("overview") }}</button>
              </li>
              <li v-if="lesson.quiz" class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Quizresults"
                  type="button"
                  role="tab"
                  aria-controls="Quizresults"
                  aria-selected="false"
                >{{ $tc('quiz_result') }}</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="Overview"
                role="tabpanel"
                aria-labelledby="Overview-tab"
              >
                <div v-html="lesson.description"></div>
              </div>
              <div
                class="tab-pane fade"
                id="Quizresults"
                role="tabpanel"
                aria-labelledby="Quizresults-tab"
              >
                <div class="card">
                  <div class="card-header p-3 p-xxl-4 pb-0 pb-xxl-0">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2 fw700 fs-18">{{ $tc("recent_quiz_result") }}</h6>
                    </div>
                  </div>
                  <div class="table-responsive p-3 p-xxl-4 pt-3 pt-xxl-3">
                    <table
                      class="table align-items-center table-quiz-result mb-0"
                    >
                      <tbody>
                        <tr
                          v-for="item in resultQuizzes"
                          :key="item.id"
                          style="cursor: pointer;"
                          @click="showQuiz(item.id)"
                        >
                          <td>
                            <div class="col col_time">
                              <p class="title">{{ $tc('start') }}:</p>
                              <h6 class="content">{{ item.start }}</h6>
                            </div>
                          </td>
                          <td>
                            <div class="col col_less">
                              <p class="title">{{ $tc('finish') }}:</p>
                              <h6 class="content">{{ item.finish }}</h6>
                            </div>
                          </td>
                          <td>
                            <div class="col col_spent">
                              <p class="title">{{ $tc('time_spent') }}:</p>
                              <h6 class="content">{{ item.time_spent_text }}</h6>
                            </div>
                          </td>
                          <td>
                            <div class="col col_points">
                              <p class="title">{{ $tc('points') }}:</p>
                              <h6 class="content">{{ `${item.grade}/${maxGrade}`}}</h6>
                            </div>
                          </td>
                          <td>
                            <div class="col col_status">
                              <p class="title">{{ $tc('status') }}:</p>
                              <h6 class="content">
                                <span :class="item.summary.includes('pass') ? 'passed' : 'failed'">{{ item.summary }}</span>
                              </h6>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- information end -->
        <!-- quiz result start -->
        <div class="col-md-4 col-xl-3 course-sidebar widget__sidebar">
          <div
            v-if="lesson.quiz"
            class="card p-3 quiz_result"
          >
            <h2 class="widget__title">{{ $tc('your_quiz_result') }}</h2>
            <p class="fs-14 fw-400 subtitle">{{ lesson.name }}</p>
            <div v-if="lastResultQuiz" class="count">
              <span class="big">{{ lastResultQuiz.grade }}</span>
              <span class="small">/{{ maxGrade }}</span>
            </div>
            <router-link
              v-if="enableQuiz"
              class="btn btn-outline-dark mt-2" :to="`/quiz/${lesson.quiz.id}`"
              type="button"
            >
              {{ getStatusQuiz() }}
            </router-link>
            <p
              v-if="lastResultQuiz && lastResultQuiz.summary === 'doing' "
              class="note fs-14 fw-400 text-center"
            >{{ `Started from ${lastResultQuiz.start}` }}</p>
            <p
              v-if="!lastResultQuiz"
              class="note fs-14 fw-400 text-center"
            >{{ $tc('you_havent_done_any_quiz') }}</p>
          </div>
        </div>
        <!-- quiz result end -->
      </div>
      <!-- information, quiz results end -->
    </div>

    <!-- modal rating -->
    <modal-rating
        v-if="course.id"
      ref="modalRating"
      :submit="openModalRatingThanks"
      :courseId="course.id"
      @reloadLessonAfterRating="reloadLessonAfterRating"
    ></modal-rating>
    <!-- modal do quiz -->
    <modal-do-quiz
      ref="modalDoQuiz"
      :doQuiz="doQuiz"
      :currentCourse="course"
      :currentLesson="lesson"
      @navigateToNextLessonFromDoQuiz="navigateToNextLesson"
    ></modal-do-quiz>
    <!-- modal do quiz again -->
    <modal-do-quiz-again
      ref="modalDoQuizAgain"
      :doQuiz="doQuiz"
      :currentCourse="course"
      :currentLesson="lesson"
      @navigateToNextLessonFromDoQuizAgain="navigateToNextLesson"
    ></modal-do-quiz-again>
    <!-- modal thanks -->
    <modal-thanks
      ref="modalThank"
    ></modal-thanks>

    <Footer />
  </div>
</template>

<script>
// import componens
import Footer from "../../layouts/Footer.vue"
import ModalRating from "./components/ModalRating.vue"
import ModalDoQuiz from "./components/ModalDoQuiz.vue"
import ModalDoQuizAgain from "./components/ModalDoQuizAgain.vue"
import ModalThanks from "./components/ModalThanks.vue"
// import libs
import { get } from 'lodash'
import imgCourse from "../../assets/images/Rectangle3015.png"
// import services
import CourseService from '../../services/course_service'
import LessonsService from '../../services/lessons_service'

import { createNamespacedHelpers } from 'vuex'

const { mapActions: mapActionsCommon } = createNamespacedHelpers('common')

export default {
  components: { Footer, ModalRating, ModalDoQuiz, ModalDoQuizAgain, ModalThanks },
  data() {
    return {
      imgCourse,
      course: {},
      courseSlug: '',
      lesson: {},
      quiz: null,
      options: { quality: { default: '1080p' } },
      duration: null,
      player: null
    };
  },
  computed: {
    completeLesson() {
      return get(this.lesson, 'student_learning.status') === 'completed'
    },
    statusLesson() {
      return get(this.lesson, 'student_learning.status')
    },
    resultQuizzes() {
      // return quiz || []
      return get(this.lesson, 'student_quizzes', [])
    },
    maxGrade() {
      return get(this.lesson, 'quiz.max_grade', 10)
    },
    lastResultQuiz() {
      // return last 100 quizzes result || null
      return this.resultQuizzes.length ? this.resultQuizzes.at(-1) : null
    },
    enableQuiz() {
      return get(this.lesson, 'quiz.remaining_attempts') !== 0
    }
  },
  watch: {
    'lesson.content': {
      handler(val) {
        if (this.$refs.plyr) {
          this.$refs.plyr.player.src = val
          this.$refs.plyr.player.source = val
          const vid = this.$refs.video;
          vid.load();
          this.$refs.plyr.player.restart()
        }
      }
    }
  },
  mounted() {
    this.getDataDetail();
    this.updateBreadcrumbs([
      { name: this.$tc('all_courses'), link: "/my-course"},
      { name: 'Course detail', link: ""}
    ]);
  },
  updated() {
    // this.$refs.plyr.addEventListener('playing', e => {
    //   console.log(e);
    // })
  },
  methods: {
    ...mapActionsCommon(['updateBreadcrumbs']),
    async getDataDetail(targetLessonId) {
      const { id } = this.$route.params;
      const response = await CourseService.getDetail(id);
      const data = get(response, 'data');
      if (data.code === 200) {
        const lessonIdGetFromUrl = this.getLessonId('lesson_id')
        // if we have the lessonId from url query, load the corresponding lesson
        if (lessonIdGetFromUrl) {
          data.data.student_learning.current_lesson_id = Number(lessonIdGetFromUrl);
        }
        // get targetLessonId and navigate to next lesson when user click on cancel button on ModalDoQuiz or ModalDoQuizAgain
        if (targetLessonId) {
          data.data.student_learning.current_lesson_id = Number(targetLessonId);
        }
        this.course = data.data;
        this.courseSlug = this.course.name.toLowerCase().replaceAll(' ', '_');
        // lessonId here can comes from URL query (continue studying lesson), or from the course itself (closest lesson)
        const lessonId = get(this.course, 'student_learning.current_lesson_id')
        if (lessonId) {
          this.getDetailLesson(lessonId);
        }
      }
    },
    async getDetailLesson(id, index) {
      const response = await LessonsService.getDetail(id)
      const data = get(response, 'data')
      if (data.code === 200) {
        this.lesson = data.data;
        this.quiz = data.data.quiz;
        this.course.lessons[index] = data.data;
      }
    },
    async markComplete() {
      const response = await LessonsService.updateStatus(this.lesson.id)
      const data = get(response, 'data')
      if (data.code === 200) {
        this.lesson.student_learning.status = 'completed';
        const index = this.course.lessons.findIndex(item => item.id === this.lesson.id);
        this.course.lessons[index].student_learning.status = 'completed';
      }
    },
    videoTimeUpdated() {
      // this.duration = this.$refs.player.currentTime;
      // console.log(event.target.currentTime);
    },
    async endedAction() {
      // if student did quiz then show modalDoQuizAgain
      if(this.lastResultQuiz) {
        this.$refs.modalDoQuizAgain.toggleModal()
      }
      // if student have not doing any quizzes then show modalDoQuiz
      else if (this.lesson.quiz) {
        this.$refs.modalDoQuiz.toggleModal()
      }
      // if not rated then show modalRating
      else if (!this.course.student_review) {
        this.openModalRating()
      }
      this.markComplete()
    },
    doQuiz() {
      this.$router.push(`/quiz/${this.lesson.quiz.id}`)
    },
    showQuiz(id) {
      this.$router.push(`/quiz-result/${id}`)
    },
    getStatusQuiz() {
      if (!this.lastResultQuiz) { return 'Do quiz' }
      return this.lastResultQuiz.summary === 'doing' ? 'Continue' : 'Do quiz again'
    },
    openModalRating() {
      this.$refs.modalRating.toggleModal()
    },
    openModalRatingThanks() {
      this.$refs.modalThank.toggleModal()
    },
    getLessonId(queryName) {
      const lessonId = this.$route.query[queryName];
      return Number(lessonId);
    },
    navigateToNextLesson(nextLessonId) {
      this.getDataDetail(nextLessonId);
    },
    reloadLessonAfterRating() {
      this.getDataDetail(this.lesson.id);
    },
  },
};
</script>

<style lang="scss" scoped>
  table {
    tr:hover {
      background-color: #f8f9fa;
    }
  }
  .lesson-active {
    color: #4b1f78 !important;
  }
  .video {
    object-fit: cover;
    object-position: center;
  }
  ::v-deep {
    .plyr--video {
      // max-height: 600px !important;
    }
  }
  .videoBlock {
    height: unset;
    max-height: unset !important;
  }
  .lessonsBlock {
    height: 100%;
  }
</style>
